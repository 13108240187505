<template>
<div class="exchange-detail">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="exchange-detail-cont">
    <div class="exchange-detail-cont-top">
      <div class="exchange-detail-cont-top-tit"><span>阿里云1+x/ACA/大数据</span><span>刷题激活码</span></div>
      <div><span>刷题激活码</span><span>|</span><span>库存共79</span><span>|</span><span>剩余库存 56</span><span>|</span><span>已兑换23</span></div>
    </div>
    <div class="search">
      <div class="search-item">
        <div class="name">
          库存导入批次筛选:
        </div>
        <div class="search-cont">
          <Select v-model="model1" style="width:200px">
            <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          用户搜索:
        </div>
        <div class="search-cont">
          <Input v-model="value" placeholder="姓名/ID/手机号" style="width: 200px" />
        </div>
      </div>
    </div>
    <div class="table">
      <Table border :columns="columns" :loading="loading" :data="data"></Table>
    </div>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "exchangeDetail",
  data(){
    return{
      total:0,
      page:1,
      pageSize:10,
      loading:false,
      columns:[
        {
          title: '用户ID',
          key: 'id',
        },
        {
          title: '兑换用户',
          key: 'id',
        },
        {
          title: '用户手机号',
          key: 'id',
        },
        {
          title: '兑换时间',
          key: 'id',
        },
        {
          title: '兑换详细',
          key: 'id',
        }
      ],
      data:[],
    }
  },
  methods:{
    getList(){

    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.exchange-detail{
  margin: 20px;
  font-size: 14px;
  .exchange-detail-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    .exchange-detail-cont-top{
      padding: 20px;
      background: #F6F7FA;
      .exchange-detail-cont-top-tit{
        margin-bottom: 10px;
        font-weight: bold;
      }
      >div{
        >span{
          padding: 0 5px;
        }
      }
    }
    .search{
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      .search-item{
        margin-bottom: 15px;
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;

        .name{
          padding: 5px 0;
          text-align: right;
        }
        .search-cont{
          margin-left: 15px;
          flex: 1;
          display: flex;
          justify-content: space-between;

          .values{
            display: flex;
            justify-content: flex-start;
            flex-flow: wrap;
            transition: max-height 0.3s linear;
            overflow: hidden;

            >div{
              padding: 5px 10px;
              //margin: 0 10px 10px 5px;
              cursor: pointer;
            }
            >div.active{
              background-color: #2d8cf0;
              color:#FFFFFF;
              border-radius: 4px;
            }
          }
          .more{
            padding: 5px 0;
            width: 80px;
            text-align: right;
            cursor: pointer;
            .more-icon{
              transition: transform 0.3s linear;
            }
          }
        }
      }
    }
    .table{
      margin-top: 20px;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
